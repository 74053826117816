// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bj_k4";
export var caseStudyBackground__lineColor = "bj_kW";
export var caseStudyHead = "bj_k6";
export var caseStudyHead__imageWrapper = "bj_kT";
export var caseStudyProjectsSection = "bj_k5";
export var caseStudyQuote__bgLight = "bj_k2";
export var caseStudyQuote__bgRing = "bj_k1";
export var caseStudyVideo = "bj_k7";
export var caseStudyVideo__ring = "bj_k8";
export var caseStudy__bgDark = "bj_kS";
export var caseStudy__bgLight = "bj_kR";